"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.flip = flip;
exports.constant = constant;
exports.on = on;
exports.compose = compose;
exports.pipe = pipe;
exports.curry = curry;
// eslint-disable-line no-redeclare

// Flips the order of the arguments to a function of two arguments.
// eslint-disable-line no-redeclare
// eslint-disable-line no-redeclare

// eslint-disable-line no-redeclare
// eslint-disable-line no-redeclare
// eslint-disable-line no-redeclare
// eslint-disable-line no-redeclare

// eslint-disable-line no-redeclare
// eslint-disable-line no-redeclare
// eslint-disable-line no-redeclare
function flip(f) {
  return function (b, a) {
    return f(a, b);
  };
}

// Returns its first argument and ignores its second.
// eslint-disable-line no-redeclare
// eslint-disable-line no-redeclare
// eslint-disable-line no-redeclare
// eslint-disable-line no-redeclare
// eslint-disable-line no-redeclare
// eslint-disable-line no-redeclare
// eslint-disable-line no-redeclare
// eslint-disable-line no-redeclare
// eslint-disable-line no-redeclare
// eslint-disable-line no-redeclare

function constant(a) {
  return function () {
    return a;
  };
}

// The `on` function is used to change the domain of a binary operator.
function on(o, f) {
  return function (x, y) {
    return o(f(x), f(y));
  };
}

function compose() {
  var _this = this;

  for (var _len = arguments.length, fns = Array(_len), _key = 0; _key < _len; _key++) {
    fns[_key] = arguments[_key];
  }

  // eslint-disable-line no-redeclare
  var len = fns.length - 1;
  return function (x) {
    var y = x;
    for (var _i = len; _i > -1; _i--) {
      y = fns[_i].call(_this, y);
    }
    return y;
  };
}

function pipe() {
  var _this2 = this;

  for (var _len2 = arguments.length, fns = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    fns[_key2] = arguments[_key2];
  }

  // eslint-disable-line no-redeclare
  var len = fns.length - 1;
  return function (x) {
    var y = x;
    for (var _i2 = 0; _i2 <= len; _i2++) {
      y = fns[_i2].call(_this2, y);
    }
    return y;
  };
}

function curried(f, length, acc) {
  return function () {
    var combined = acc.concat(Array.prototype.slice.call(arguments));
    return combined.length >= length ? f.apply(this, combined) : curried(f, length, combined);
  };
}

function curry(f) {
  // eslint-disable-line no-redeclare
  return curried(f, f.length, []);
}