import _toConsumableArray from 'babel-runtime/helpers/toConsumableArray';
import _newArrowCheck from 'babel-runtime/helpers/newArrowCheck';
import { curry } from 'flow-static-land/lib/Fun';
import _extends from 'babel-runtime/helpers/extends';
import _Array$from from 'babel-runtime/core-js/array/from';
import _Object$keys from 'babel-runtime/core-js/object/keys';
import _Number$isInteger from 'babel-runtime/core-js/number/is-integer';
import _objectWithoutProperties from 'babel-runtime/helpers/objectWithoutProperties';

var _this = undefined;

/**
 * Returns a new Array with elements appended to the one given.
 */
var append = function (elements, array) {
  _newArrowCheck(this, _this);

  return [].concat(_toConsumableArray(array), _toConsumableArray(elements));
}.bind(undefined);

var append$1 = curry(append);

var _this$1 = undefined;

/**
 * Returns input if it is an Array or returns a new Array with input inside if
 * it is not.
 */
var convertIfNot = function (input) {
  _newArrowCheck(this, _this$1);

  return Array.isArray(input) ? input : [input];
}.bind(undefined);

var _this$3 = undefined;

/**
 * Returns true if given index is the last one or false otherwise.
 */
var isLastIndex = function (array, index) {
  _newArrowCheck(this, _this$3);

  return index === array.length - 1;
}.bind(undefined);

var isLastIndex$1 = curry(isLastIndex);

var _this$2 = undefined;

/**
 * Returns 0 if current index is the last one, or returns next if it is not.
 */
var cycleNext = function (array, currentIndex) {
  _newArrowCheck(this, _this$2);

  return isLastIndex$1(array, currentIndex) ? 0 : currentIndex + 1;
}.bind(undefined);

var cycleNext$1 = curry(cycleNext);

var _this$4 = undefined;

var getObjectLength = function (object) {
  _newArrowCheck(this, _this$4);

  return Math.max.apply(Math, _toConsumableArray(_Object$keys(object))) + 1;
}.bind(undefined);

/**
 * Creates a new array using the given object
 * If all of its entries are array keys.
 * 
 * (it could also have a property length with its size)
 */
var fromObject = function (object) {
  _newArrowCheck(this, _this$4);

  return _Array$from("length" in object ? object : _extends({}, object, { length: getObjectLength(object) }));
}.bind(undefined);

var _this$5 = undefined;

/**
 * Returns a new Array with the result of having inserted the given elements at
 * the specified index.
 */
var insert = function (index, elements, array) {
  _newArrowCheck(this, _this$5);

  return [].concat(_toConsumableArray(array.slice(0, index)), _toConsumableArray(elements), _toConsumableArray(array.slice(index + 1)));
}.bind(undefined);

var insert$1 = curry(insert);

var _this$6 = undefined;

var isIntGreaterThan = function (number, other) {
  _newArrowCheck(this, _this$6);

  return _Number$isInteger(number) && number >= other;
}.bind(undefined);

/**
 * Returns true if the given string is an Array key or false otherwise.
 */
var isKey = function (string) {
  _newArrowCheck(this, _this$6);

  return isIntGreaterThan(Number(string), 0);
}.bind(undefined);

var _this$7 = undefined;

/**
 * Returns true if an Array can be created from the given Object, or in other
 * words, if it has or not a length property, and the rest of its keys are Array
 * ones.
 */
var isPossibleFromObject = function (_ref) {
  var length = _ref.length,
      rest = _objectWithoutProperties(_ref, ["length"]);

  _newArrowCheck(this, _this$7);

  return _Object$keys(rest).every(isKey);
}.bind(undefined);

var _this$8 = undefined;

/**
 * Returns a new Array with elements prepended to the one given.
 */
var prepend = function (elements, array) {
  _newArrowCheck(this, _this$8);

  return [].concat(_toConsumableArray(elements), _toConsumableArray(array));
}.bind(undefined);

var prepend$1 = curry(prepend);

var _this$9 = undefined;

/**
 * Reduce the given array applying reduce function only to elements filtered.
 */
var reduceIf = function (filter, reduce, resultInitial, array) {
  _newArrowCheck(this, _this$9);

  return array.reduce(function (result, element, index) {
    _newArrowCheck(this, _this$9);

    return filter(element, index, result) ? reduce(result, element, index) : result;
  }.bind(this), resultInitial);
}.bind(undefined);

var reduceIf$1 = curry(reduceIf);

var _this$10 = undefined;

/**
 * Reduce the given array applying reduce function while shouldProceed function
 * returns true.
 */
var reduceWhile = function (shouldProceed, reduce, resultInitial, array) {
  _newArrowCheck(this, _this$10);

  var result = resultInitial;

  array.every(function (element, index) {
    _newArrowCheck(this, _this$10);

    var proceed = shouldProceed(element, index, result);

    if (proceed) {
      result = reduce(result, element, index);
    }

    return proceed;
  }.bind(this));

  return result;
}.bind(undefined);

var reduceWhile$1 = curry(reduceWhile);

var _this$11 = undefined;

/**
 * Returns a new Array with the result of having removed the specified amount
 * (count) of elements at the given index.
 */
var remove = function (index, count, array) {
  _newArrowCheck(this, _this$11);

  return [].concat(_toConsumableArray(array.slice(0, index)), _toConsumableArray(array.slice(index + count)));
}.bind(undefined);

var remove$1 = curry(remove);

var _this$12 = undefined;

/**
 * Returns a new Array with the given size (count) filled with the specified
 * element.
 */
var repeat = function (count, element) {
  _newArrowCheck(this, _this$12);

  return [].concat(_toConsumableArray(Array(count))).map(function () {
    _newArrowCheck(this, _this$12);

    return element;
  }.bind(this));
}.bind(undefined);

var repeat$1 = curry(repeat);

var _this$13 = undefined;

/**
 * Returns a new Array with the result of having replaced the elements at the
 * given index with the ones specified.
 */
var replace = function (index, elements, array) {
  _newArrowCheck(this, _this$13);

  return [].concat(_toConsumableArray(array.slice(0, index)), _toConsumableArray(elements), _toConsumableArray(array.slice(index + elements.length)));
}.bind(undefined);

var replace$1 = curry(replace);

var _this$14 = undefined;

/**
 * Returns an absolute index from a relative one.
 * 
 * Relative indexes differ from absolute ones in that they can be negative and
 * in those cases it would be as simple as substracting them from the length of
 * the array from where they belong to obtain their absolute counterparts.
 */
var resolveIndex = function (array, relativeIndex) {
  _newArrowCheck(this, _this$14);

  return relativeIndex < 0 ? array.length - relativeIndex : relativeIndex;
}.bind(undefined);

var resolveIndex$1 = curry(resolveIndex);

export { append$1 as append, convertIfNot, cycleNext$1 as cycleNext, fromObject, insert$1 as insert, isKey, isLastIndex$1 as isLastIndex, isPossibleFromObject, prepend$1 as prepend, reduceIf$1 as reduceIf, reduceWhile$1 as reduceWhile, remove$1 as remove, repeat$1 as repeat, replace$1 as replace, resolveIndex$1 as resolveIndex };
